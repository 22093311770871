export const feeLeaseholdVisuals = [
  {
    name: "Demo",
    markdown: `![Fee leasehold GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const feeSubLeaseholdVisuals = [
  {
    name: "Demo",
    markdown: `![Fee leasehold GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const condoLeaseholdVisuals = [
  {
    name: "Demo",
    markdown: `![Fee leasehold GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const condoSubLeaseholdVisuals = [
  {
    name: "Demo",
    markdown: `![Fee leasehold GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addLeasehold = {
  order: 14,
  name: "Add a leasehold",
  keywords: "ground lease sublease sub-lease",
  subcategory: "Properties",
  markdown: `# Add a leasehold

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  Leasehold interests are a common way of controlling sites in certain markets. The most common arrangement is a leasehold granted by the fee parcel owner. Occasionally, the lessee in a leasehold will grant a sub-leasehold to another operator. Rarely, a leasehold may be granted by a condominium or co-op owner. Tower Hunt is able to represent all three of these {% inlineRouterLink %}leasehold{% /inlineRouterLink %} scenarios.

  {% callout type="caution" marginStyles="mb-4" %}
  **Important**: This command saves **immediately**.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Fee parcels" %}fee parcel{% /inlineRouterLink %}, {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Leaseholds (optional)" %}leasehold{% /inlineRouterLink %} or {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land covering ownership units (optional)" %}land covering ownership unit{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="insertGroundLease" /%}.
  4. A new leasehold appears above the selected property right. See below for examples.
  {% /tablessContainer %}

  ## Visuals

  ### Leasehold on fee parcel

  {% tabbedContainer tabs=$feeLeaseholdVisuals /%}

  ### Sub-leasehold on fee parcel

  {% tabbedContainer tabs=$feeSubLeaseholdVisuals /%}

  ### Leasehold on condominium unit

  {% tabbedContainer tabs=$condoLeaseholdVisuals /%}

  ### Sub-leasehold on condominium unit

  {% tabbedContainer tabs=$condoSubLeaseholdVisuals /%}
  `,
};
